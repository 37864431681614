<template>
  <Layout>
    <Header />
    <div class="container-fluid p-0">
      <div class="banner position-relative">
          <div id="serviceBanner"><img src="@/assets/images/app/services.jpg" :alt="title" class="img-fluid d-block"></div>
          <div class="position-absolute top-50 start-0 translate-middle-y w-100">
            <div class="container text-white px-3">
                <div class="row justify-content-end">
                <div class="col-lg-8 col-10 text-end">
                  <h1 class="text-white display-4 animate__animated animate__slideInRight font-style-2 mt-4 mt-md-0">{{$t('services')}}</h1>
                  
                  <p class="text-end animate__animated animate__fadeInDown mb-2 mb-lg-3 d-none d-lg-block">{{$t('service_content')}}</p>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
    <div class="container my-3 my-lg-5 servicesPage">
      <div class="row">
        <div class="col-12">
              <b-card no-body class="mb-1 shadow-none" v-for="(value,index) in Services" :key="index" :id="'service_'+index" >
                <b-card-header header-tag="header" role="tab" >
                  <h6 class="m-0">
                    <a
                      v-b-toggle="'accordion-'+index"
                      class="text-primary fw-semibold d-block font-style-2 text-capitalize font-size-22"
                      href="javascript: void(0);"
                      >{{$t(value.title)}}</a
                    >
                  </h6>
                </b-card-header>
                <b-collapse
                  :id="'accordion-'+index"
                  :visible="value.visible"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body class="font-size-14">
                    <b-card-text>
                      <div class="py-2">
                        <div class="mb-3" v-if="$t('services') == 'Services'" v-html="value.content"></div>
                        <div class="mb-3" v-else v-html="value.contentCN"></div>
                        <ul class="list-unstyled" v-if="$t('services') == 'Services'">
                          <li v-for="(x,y) in value.list" :key="y">
                            <h3 class="text-primary font-size-18 fw-semibold text-capitalize font-style-2">{{ x.title }}</h3>
                            <p class="mb-4">{{ x.content }}</p>

                          </li>
                        </ul>
                        <ul class="list-unstyled" v-else>
                          <li v-for="(x,y) in value.listCN" :key="y">
                            <h3 class="text-primary font-size-18 fw-semibold text-capitalize font-style-2">{{ x.title }}</h3>
                            <p class="mb-4">{{ x.content }}</p>

                          </li>
                        </ul>
                      </div>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
        </div>
      </div>
    </div>
    <Enquiry />
    <Contact />
    <Common ref="commonFunc"/>
  </Layout>
</template>
<script>
import Layout from "@/views/layouts/main";
import appConfig from "@/app.config";
import Header from "@/views/pages/drlim/components/header";
import Enquiry from '@/views/pages/drlim/components/enquiry';
import Contact from '@/views/pages/drlim/components/contact';
import Common from "@/components/common";
/**
 * Starter page
 */
export default {
  components: { Layout , Header, Enquiry, Contact, Common},
  page()  {
    return {
      title: "Services",
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    }
  },
  watch: {
    // Watch for changes to the $route object
    $route(to, from) {
      console.log(to, from)
      const id = to.params.id
      this.Services.forEach((service, index) => {
        if (index === parseInt(id)) {
          service.visible = true;
          document.title = `${this.Services[parseInt(id)].title}  | Dr. Lim Surgery - Consultant General & Colorectal Surgeon`;
        } else {
          service.visible = false;
        }
      });
      this.handleHashChange()
      this.$forceUpdate();
    },
    // $route.hash: function (newHash, oldHash) {
    //   if (newHash && document.getElementById(newHash)) {
    //     // Scroll to the element with the corresponding ID
    //     document.getElementById(newHash).scrollIntoView();
    //   }
    // }
  },
  data() {
    return {
      title: appConfig.title,
      items: [],
      showContent:true,
      Services:[
          {
              title: "rectal_polyps_and_cancers",
              content: "Most of the cancers develop/progress from a polyp. Polyp is an abnormal growth on the inner lining of the rectum. This is caused by excessive growth of cells and it carries a potential risk of cancer formation. Certain features are associated with increased risk of cancer - size and the behaviour of the cells.",
              contentCN: "大多数癌症都是由息肉发展/进展的。 息肉是直肠内壁的异常生长。 这是由细胞过度生长引起的，并且具有形成癌症的潜在风险。 息肉的某些特征与癌症风险增加相关——息肉的大小和病变的特性。",
              list:[
                {
                  "title": "Transanal excision",
                  "content": "For a very low lying polyp or early cancer that is within 8-10 cm from the anal opening, removal is possible through the anus. The anus is stretched and removal is done using conventional instruments."
                },
                {
                  "title": "Transanal minimally invasive surgical excision",
                  "content": "This is done similarly to transanal excision, but with instruments used for keyhole surgeries. Polyp or tumour higher could be removed because of the utilization of longer instruments"
                },
                {
                  "title": "Laparoscopic anterior resection",
                  "content": "Removal of rectum is performed with keyhole surgery (minimal access surgery) where special instruments are inserted through several small incisions on the abdominal wall. The postoperative pain is significantly lower compared to conventional open surgery because the incisions are much smaller. This has been the standard of care for rectal cancer for most circumstances."
                },
                {
                  "title": "Open anterior resection",
                  "content": "Unlike laparoscopic surgery, this is done through a conventional incision for optimal access to the tumour or target organ. It is usually done for conditions that are not amenable to minimally invasive surgery (large tumour, involvement of multiple organs and etc)."
                }
              ],
              listCN:[
              {
                  "title": "经肛门切除手术",
                  "content": "对于位于离肛门开口8至10厘米内的低位息肉或初期肿瘤可以通过肛门来进行切除。肛门在手术时会被扩张，并通过仪器来进行切除。"
                },
                {
                  "title": "经肛门微创切除手术",
                  "content": "这跟经肛门切除术相似，但是使用的是用于微创手术的仪器来进行。由于使用了比较长的仪器，所以在处于比较高位置的息肉或肿瘤也可以被切除。"
                },
                {
                  "title": "微创直肠切除手术",
                  "content": "通通过腹部上的几个小切口插入特殊仪器并使用微创手术来切除直肠。与传统开放手术相比，由于切口小得多，术后疼痛明显减轻。这已经成为大多数直肠癌的标准治疗方案。"
                },
                {
                  "title": "开放性直肠切除手术",
                  "content": "与微创手术不同的是，为了可以更容易处理特定的肿瘤或目标器官，大肠切除手术可以通过传统开放性切口来进行的。这通常是针对不适合进行微创手术的情况下而进行（大肿瘤、或者涉及多个器官等）。"
                }
              ]
            },
            {
              title: "colon_polyps_and_cancers",
              content: "Most of the cancers develop/progress from a polyp. Polyp is an abnormal growth on the inner lining of the rectum. This is caused by excessive growth of cells and it carries a potential risk of cancer formation. Certain features are associated with increased risk of cancer - size and the behaviour of the cells.",
              contentCN: "大多数癌症都是由息肉发展/进展的。 息肉是直肠内壁的异常生长。 这是由细胞过度生长引起的，并且具有形成癌症的潜在风险。 息肉的某些特征与癌症风险增加相关——息肉的大小和病变的特性。",
              list: [
                {
                  title: "Endoscopic mucosal resection (EMR)",
                  content: "For larger polyps in the colon, simple polypectomy might not be suitable. EMR involves raising a polyp from its base and then removing it using a snare (a metal wire around the polyp). The defect at the base is then closed with clips."
                },
                {
                  title: "Endoscopic submucosal dissection (ESD)",
                  content: "This is a special and more challenging technique that involves entering a specific layer of the colonic wall to remove a polyp or tumour completely."
                },
                {
                  title: "Laparoscopic colectomy",
                  content: "Removal of colon is performed with keyhole surgery (minimal access surgery) where special instruments are inserted through several small incisions on the abdominal wall. The postoperative pain is significantly lower compared to conventional open surgery because the incisions are much smaller. This has been the standard of care for colon cancer for most circumstances."
                },
                // {
                //   title: "Robotic colectomy",
                //   content: "This is performed similarly to laparoscopic surgery, but with the utilization of a robot. The robot is controlled by the surgeon from the surgeon console that is located away from the patient. The surgeon movement is more refined with elimination of tremors, better access to narrow spaces, better vision in 3 dimensions."
                // },
                {
                  title: "Single incision colectomy",
                  content: "This is a variation of laparoscopic surgery, where the surgery is performed by passing all the instruments through a single incision, usually at the umbilicus. Compared to conventional laparoscopic surgery, the postoperative pain is lower because it has only 1 incision."
                }
              ],
              listCN: [
                {
                  title: "黏膜内切除手术（EMR）",
                  content: "对于结肠中比较大的息肉，简单的息肉切除手术可能不合适。EMR将息肉从从其基部抬起，然后使用圈套器（环绕着息肉的金属线）将其切除。然后用夹子关闭底处的缺口。"
                },
                {
                  title: "内窥镜粘膜切除手术（ESD）",
                  content: "这是一种特殊且更具挑战性的技术，医生将会使用内窥镜进入结肠壁的特定层以完全切除息肉或肿瘤。"
                },
                {
                  title: "微创（腹腔）结肠切除手术",
                  content: "通过腹部上的几个小切口插入特殊仪器并使用微创手术来切除结肠。与传统开放手术相比，由于切口小得多，术后疼痛明显减轻。这已经成为大多数结肠癌的标准治疗方案。"
                },
                {
                  title: "单孔结肠切除手术",
                  content: "这是微创手术的其中一种，手术是将所有器械穿过单个切口（通常位于脐部）来进行。 与传统腹腔镜手术相比，由于只有1个切口，术后疼痛较低。"
                }
              ]
            },
            {
              title: "roboti_surgery",
              content: "Many minimally invasive surgeries can be done with robots. These robots offer many benefits and advantages:<ul><li>Greater precision</li><li>Smaller incisions</li><li>Less pain</li><li>Less blood loss</li><li>Shorter hospital stays and faster return to normal activities.</li></ul>",
              contentCN: "许多微创手术可以用机器人完成。 这些机器人具有许多优点和优势:<ul><li>更高的精准度</li><li>较小的切口</li><li>减少疼痛</li><li>失血较少</li><li>住院时间更短，更快恢复正常活动。</li></ul>",
              list: [
                {
                  title: "Robotic Anterior Resection and Colectomy",
                  content: "Removal of rectum is performed with a robot where robotic instruments are inserted through several small incisions on the abdominal wall. The extra dexterity of the robotic instruments and the precision in the narrow pelvic cavity make it the ideal modality to perform anterior resection. "
                },
                {
                  title: "Robotic Inguinal Hernia Repair",
                  content: "Inguinal hernia can also be repaired robotically. The incisions are smaller so the postoperative pain is less. They are especially useful in recurrent inguinal hernia that was repaired with open technique."
                },
                {
                  title: "Robotic Ventral Hernia Repair",
                  content: "Ventral hernias can be repaired robotically as well."
                },
              ],
              listCN: [
                {
                  title: "机器人结直肠切除手术",
                  content: "通过腹部上的几个小切口插入机器人手臂并使用微创手术来切除结直肠。与腹腔手术相比，机器人器械的额外灵活性和精准性使其成为结直肠切除术的理想方式，特别是在盆腔里的直肠切除手术。"
                },
                {
                  title: "机器人腹股沟疝修补手术",
                  content: "腹股沟疝气也可以通过机器人来修补。机器人手术的切口较小，所以手术后的疼痛感也会比较轻。对于复发性腹股沟疝气（尤其是对于之前的手术是通过开放性来进行的），机器人手术会特别有用。"
                },
                {
                  title: "微创腹壁疝修补手术",
                  content: "腹壁疝也可以通过机器人来进行修补。"
                }
              ]
            },
            {
              title: "endoscopy",
              content: "Endoscopy allows us to inspect the inner lining of the gastrointestinal tract and remains the gold standard in screening, removal of polyps and biopsy of tumour. It involves insertion of a fibreoptic camera system into the gastrointestinal tract through a natural orifice such as the mouth and anus.",
              contentCN: "内窥镜检查可让医生观察肠胃道的内膜，并同时间检查并进行息肉切除和做肿瘤活检。它涉及通过口腔和肛门等自然孔口将光纤摄像系统插入胃肠道以进行检查。",
              list:[
                {
                title: "Gastroscopy",
                content: "This allows us to examine the oesophagus (food pipe), stomach and duodenum. Biopsy and polypectomy can be performed if necessary. Treatment for bleeding ulcer can also be performed with these methods: thermal energy, clips, medication injection and spray."
                },
                {
                title: "colonoscopy",
                content: "A fibreoptic camera with a light source is inserted into the colon via the anus. Biopsy and polypectomy can be performed if necessary. Colonoscopy has remained as the gold standard in screening for colorectal cancer."
                },
                {
                title: "Therapeutic stenting of the colon",
                content: "A metallic stent can be inserted to relieve blockage of the colon due to tumour. It can be a permanent fix for advanced cancer or a temporary treatment as a bridge to subsequent definitive surgery."
                },
                {
                title: "Endoscopic mucosal resection (EMR)",
                content: "For larger polyps in the colon, simple polypectomy might not be suitable. EMR involves raising a polyp from its base and then removing it using a snare (a metal wire around the polyp). The defect at the base is then closed with clips."
                },
                {
                title: "Endoscopic submucosal dissection (ESD)",
                content: "This is a special and more challenging technique that involves entering a specific layer of the colonic wall to remove a polyp or tumour completely."
                }
              ],
              listCN:[
                {
                title: "肠胃镜检查",
                content: "这种类型的检查可以检查食道、胃和十二指肠。在有必要时还可以进行活检和息肉切除。对于溃疡出血，我们可以用热能、夹子、药物注射和喷雾来进行治疗。"
                },
                {
                title: "大肠镜检查",
                content: "通过肛门植入带有光源的光纤镜头到大肠内。在有必要时还可以进行活检和息肉切除。大肠镜检查仍然是检测结大肠癌的最佳检测方案。"
                },
                {
                title: "支架置入大肠治疗",
                content: "医生将植入金属支架来缓解因结肠肿瘤造成的梗阻。它可以是晚期癌症的永久治疗，也可以作为后续治疗性手术的过渡行治疗。"
                },
                {
                title: "黏膜内切除手术（EMR）",
                content: "对于结肠中比较大的息肉，简单的息肉切除手术可能不合适。EMR将息肉从从其基部抬起，然后使用圈套器（环绕着息肉的金属线）将其切除。然后用夹子关闭底处的缺口。"
                },
                {
                title: "内窥镜粘膜切除手术（ESD）",
                content: "这是一种特殊且更具挑战性的技术，医生将会使用内窥镜进入结肠壁的特定层以完全切除息肉或肿瘤"
                }
              ]
            },
            {
              title: "haemorrhoid_surgery",
              content: "Haemorrhoid is a condition when there is dilatation of vessels in the anus, or prolapse of the anal cushion, or both. Many individuals are affected but chose to live with it. It can be effectively treated with small operative procedures.",
              contentCN: "痔疮是肛门血管扩张或肛垫脱垂或两者兼而有之的一种疾病。这种病症非常的普遍，很多人都选择忽视它。其实痔疮可以有效的通过小型手术来治疗。",
              list:[
              {
              title: "Rubber band ligation",
              content: "A rubber band is applied to the base of the haemorrhoid. This will cut off the blood supply to the haemorrhoid. Eventually the haemorrhoid dies off and drops after 3 to 5 days. This is an effective treatment for small haemorrhoids and can be done easily at the bedside without any sedation."
              },
              {
              title: "Laser haemorrhoid treatment",
              content: "This is a minimally invasive surgery for haemorrhoid. A small puncture is made on the haemorrhoid to allow passage of a laser fibre. Laser energy is applied within the haemorrhoid. This causes clotting of the blood within the haemorrhoid and an intense inflammation around the haemorrhoid. This inflammation will lead to scarring around the haemorrhoid, causing fixation of the enlarged haemorrhoid. The obvious advantage of this method is the minimal downtime as the pain is kept to the minimum."
              },
              {
              title: "Conventional haemorrhoidectomy",
              content: "For larger and prolapsed haemorrhoid, conventional haemorrhoidectomy remains valuable although it causes significant postoperative pain. This involves removing the enlarged haemorrhoid and the prolapsed anal cushion. The resulting wound can then be left open or closed with suture."
              }
              ],
              listCN:[
              {
              title: "胶圈套扎治疗",
              content: "将橡皮筋绑在痔疮的底部。 这会切断痔疮的血液供应。痔疮最终会在3至5天后自然脱落。这是治疗小痔疮的有效方法，并且可以在床边轻松完成，无需任何镇静剂。"
              },
              {
              title: "激光痔疮治疗",
              content: "这是一种治疗痔疮的微创手术。医生将会在痔疮做一个小穿刺，让激光纤维通过来进行治疗。这会导致痔疮内部的血液凝结以及照成痔疮周围组织发炎。这种类型的发炎会导致痔疮的周围形成结疤并使肿大的痔疮固定下来。这种治疗方案的优点是康复时间短，因为疼痛被降到最低。"
              },
              {
              title: "普通痔疮切除手术",
              content: "对于较大且脱垂的痔疮，虽然手术后会造成疼痛，传统痔疮切除手术仍然是有必要的。这包含到切除肿大的痔疮和脱垂的肛垫。然后可以将形成的伤口保持开放或用缝合线闭合。"
              }
              ]
            },
            {
              title: "anal_fistula",
              content: "Fistula is a condition where there is an abnormal connection between the inside of the anus (internal opening) and the skin around the anus (external opening). Surgery is the treatment of choice as spontaneous closure of the tract is unlikely. There are many types of fistula and the treatment of choice depends on the type of fistula.",
              contentCN: "肛瘘是肛门内部（内部开口）与肛门周围皮肤（外部开口）之间存在异常连接的疾病。手术是首选的治疗方法，因为管道自发闭合的可能性不大。瘘管有多种类型，治疗方法的选择取决于瘘管的类型。",
              list:[
                {
                  "title": "Fistulotomy",
                  "content": "If the fistula is superficial and without significant involvement of the sphincter muscles around the anus, the fistula tract can be laid open safely. The wound is then left open for dressing and allowed to heal."
                },
                {
                  "title": "Endorectal advancement flap",
                  "content": "This is one method when the internal opening is covered by pulling down a tongue of anal tissue. This prevents faecal material from entering the fistula tract and hence preventing inflammation and infection, with subsequent closure of the tract. The sphincter will not be cut with this method. This is usually done for high fistula as cutting of sphincter may pose significant risk of incontinence."
                },
                {
                  "title": "Ligation of intersphincteric fistula tract (LIFT)",
                  "content": "This is a newer technique where the part of the fistula lying in between the inner and outer layer of the sphincter is divided. This is achieved by entering the natural plane in between the sphincters, followed by identification of the fistula tract and cutting of the tract. The incision wound is then closed with suture."
                },
                {
                  "title": "Laser assisted closure",
                  "content": "A minimally invasive method to treat fistula. The fistula tract is identified and laser fibre is then inserted into the tract. Laser energy is applied while slowing withdrawing the fibre from the internal to external opening. This laser energy will lead to closure of the tract. The risk of sphincter injury is minimal as there is no cutting of the sphincter at all."
                }
              ],
              listCN:[
                {
                  "title": "肛瘘切开手术",
                  "content": "如果肛瘘的表面较浅表且没有明显伤到肛门括约肌，医生可以安全地将瘘道切开。然后将伤口打开进行包扎并使其愈合。"
                },
                {
                  "title": "直肠内推进皮瓣",
                  "content": "这是一种通过拉下一块肛门组织来覆盖肛瘘内口的治疗。这可以防止粪便进入瘘管，从而防止炎症和感染，随后关闭瘘管。 此方法不会切割括约肌。 这通常用于高位瘘管，因为切除括约肌可能会带来严重的失禁风险。"
                },
                {
                  "title": " 括约肌间瘘道结扎术（LIFT）",
                  "content": "这是一种比较新的治疗方案，把位于内括约肌及外括约肌之间的瘘道切断。医生会把括约肌之间的缝隙分离开来，然后识别瘘道并切断瘘道来实现的。然后医生会用缝合线闭合括约肌间的切口。"
                },
                {
                  "title": "激光闭合手术",
                  "content": "这是一种通过微创手术来治疗肛瘘的方法。首先医生会识别瘘道，然后将激光光纤植入瘘道，再从伤口内部向外口缓慢拔出光纤的同时将激光输出。这种激光能量将导致瘘道闭合。 由于根本没有切割括约肌，因此括约肌受伤以致大便失禁的风险很小。"
                }
              ]
            },
            {
              title: "anal_fissure",
              content: "Anal fissure is a small tear at the anus with spasm of the anal sphincter. It causes intense sharp pain and bleeding with passage of stool.",
              contentCN: "肛裂是肛门处出现小撕裂并伴有肛门括约肌痉挛。 它会引起剧烈的剧烈疼痛和排便时出血。",
              list:[
                {
                  "title": "BOTOX injection",
                  "content": "Botox is injected into the anal sphincter to relieve anal sphincter spasm. The effect lasts for a few months and repeat injection might be needed."
                },
                {
                  "title": "Lateral internal sphincterotomy ",
                  "content": "This is a small procedure involving cutting of the internal sphincter to relieve sphincter spasm."
                }
              ],
              listCN:[
                {
                  "title": "肉毒杆菌注射",
                  "content": "医生将肉毒杆菌注射到肛门括约肌中以缓解肛门括约肌的疼痛。注射效果可维持好几个月。当效果减弱后，医生有可能会建议再进行多一个注射疗程。"
                },
                {
                  "title": "侧向内括约肌切开手术",
                  "content": "这是一个小手术，医生将会切开内括约肌以缓解括约肌痉挛。"
                }
              ]
            },
            {
              title: "laser_surgery",
              content: "The application of Laser energy is a minimally invasive modality to treat several perianal conditions, and its use is well documented and supported by clinical evidence. It aims to reduce postoperative pain and minimize inadvertent injury to the perianal structures to preserve functions. Suitable haemorrhoids and perianal fistulas can be treated with Laser.",
              contentCN: "激光能量的应用是一种治疗多种肛周疾病的微创方式，其使用已有充分的记录并得到临床数据的支持。 其目的是减少术后疼痛并尽量减少对肛周结构的无意损伤，以保留功能。 适合的痔疮和肛瘘可以用激光技术治疗。",
              list:[
                {
                  "title": "Laser Haemorrhoid Treatment",
                  "content": "This is a minimally invasive surgery for haemorrhoid. A small puncture is made on the haemorrhoid to allow passage of a laser fibre. Laser energy is applied within the haemorrhoid. This causes clotting of the blood within the haemorrhoid and an intense inflammation around the haemorrhoid. This inflammation will lead to scarring around the haemorrhoid, causing fixation of the enlarged haemorrhoid. The obvious advantage of this method is the minimal downtime as the pain is kept to the minimum."
                },
                {
                  "title": "Laser Assisted Closure",
                  "content": "A minimally invasive method to treat fistula. The fistula tract is identified and laser fibre is then inserted into the tract. Laser energy is applied while slowing withdrawing the fibre from the internal to external opening. This laser energy will lead to closure of the tract. The risk of sphincter injury is minimal as there is no cutting of the sphincter at all."
                }
              ],
              listCN:[
                {
                  "title": "激光痔疮治疗",
                  "content": "这是一种治疗痔疮的微创手术。医生将会在痔疮做一个小穿刺，让激光纤维通过来进行治疗。这会导致痔疮内部的血液凝结以及照成痔疮周围组织发炎。这种类型的发炎会导致痔疮的周围形成结疤并使肿大的痔疮固定下来。这种治疗方案的优点是康复时间短，因为疼痛被降到最低。"
                },
                {
                  "title": "激光闭合手术",
                  "content": "这是一种通过微创手术来治疗肛瘘的方法。首先医生会识别瘘道，然后将激光光纤植入瘘道，再从伤口内部向外口缓慢拔出光纤的同时将激光输出。这种激光能量将导致瘘道闭合。 由于根本没有切割括约肌，因此括约肌受伤以致大便失禁的风险很小。"
                }
              ]
            },
            {
              title: "rectal_prolapse",
              content: "Rectal prolapse occurs when part of the rectum slipped out and protruded from the anus. It is usually due to weakness of the pelvic floor muscle. ",
              contentCN: "当直肠的一部分滑出并从肛门突出时，就会发生直肠脱垂。 这通常是由于盆底肌肉无力所致。",
              list:[
                {
                  "title": "Perineal proctosigmoidectomy (Altemeier procedure)",
                  "content": "This is a surgery performed to remove the full thickness of the prolapsed rectum and sigmoid colon from the perineum. It can be done as a daycare procedure under regional anaesthesia. Although it is associated with higher recurrence rate, it is a good option for elderly who are not fit to undergo major abdominal surgery."
                },
                {
                  "title": "Perineal mucosal resection (Delorme procedure)",
                  "content": "Much like the Altermeier procedure, the only difference is only the inner lining of the prolapsed rectum and/or sigmoid colon is removed and imbricated to reduce the prolapse. "
                },
                {
                  "title": "Laparoscopic rectopexy",
                  "content": "The prolapsed rectum is free from its attachment and then suture fixation is done. This procedure “pulls” the rectum up to reduce the prolapse. This is done laparoscopically. It has a lower recurrence rate compared to perineal surgeries."
                }
              ],
              listCN:[
                {
                  "title": "会阴直肠乙状结肠切除手术（Altemeier手术）",
                  "content": "这是一项从会阴切除整个脱垂直肠和乙状结肠的手术。 它可以作为日托手术在区域麻醉下完成。 虽然复发率较高，但对于不适合接受腹部大手术的老年人来说，这是一个不错的选择。"
                },
                {
                  "title": "会阴粘膜切除手术（Delorme 手术）",
                  "content": "这种手术与 Altemeier 手术非常相似，唯一的区别是仅去除脱垂的直肠和/或乙状结肠的内层并进行叠瓦状以减少脱垂。"
                },
                {
                  "title": "腹腔镜直肠固定术",
                  "content": "将脱垂的直肠脱离其附着物，然后缝合固定。 该手术将直肠向上“提拉”以减少脱垂。 这是通过腹腔镜完成的。 与会阴手术相比，其复发率较低。"
                }
              ]
            },
            {
              title: "creation_and_closure_of_stoma",
              content: "Stoma is the exteriorization of the small or large bowel at the abdominal wall and is needed in certain conditions. It could be temporary or permanent. ",
              contentCN: "造口是将小肠或大肠的外化，也就是说将小肠或大肠拉到腹部外侧一边排出粪便，因而粪便不会经过肛门排出。这种手术在某些情况下是必须要进行的。它可以是暂时的或永久性的。",
              list:[
                {
                  "title": "Stoma creation (open/laparoscopic)",
                  "content": ""
                },
                {
                  "title": "Stoma closure",
                  "content": ""
                }
              ],
              listCN:[
                {
                  "title": "造口手术（开腹/微创）",
                  "content": ""
                },
                {
                  "title": "造口关闭手术",
                  "content": ""
                }
              ]
            },
            {
              title: "peritoneal_cancer",
              content: "Some cancers tend to spread along the inner lining of the abdomen. These cancers usually respond poorly to intravenous chemotherapy and commonly cause significant symptoms due to bowel blockage and fluid collection. ",
              contentCN: "有些癌症倾向于沿着腹部内膜扩散。 这些癌症通常对静脉化疗反应不佳，并且通常因肠道梗阻和积液而引起明显的症状。",
              list:[
                {
                  "title": "Cytoreductive surgery and Hyperthermic Intraperitoneal Chemotherapy (CRS and HIPEC)",
                  "content": "For selected peritoneal cancers without spread to other organs outside the abdomen, this can be offered as a curative treatment. All visible tumours are removed surgically, followed by administration of heated chemotherapy into the abdomen for 1 hour."
                },
                {
                  "title": "Pressurized intraperitoneal aerosol chemotherapy (PIPAC)",
                  "content": "This is usually offered to patients with peritoneal cancers who are deemed not curative (when the tumour cannot be completely removed). Chemotherapy is aerosolized and delivered to the abdomen using laparoscopy. It is usually given for a few cycles and is usually combined with intravenous chemotherapy. "
                }
              ],
              listCN:[
                {
                  "title": "肿瘤细胞减灭手术和腹腔温热灌注化疗（CRS和HIPEC）",
                  "content": "对于某些未扩散到腹部以外其他器官的腹膜癌，这可以作为一种治疗方法。 医生会通过手术切除所有可见的肿瘤，然后在腹部进行加热化疗长达 1 小时。"
                },
                {
                  "title": "腹腔高压气雾化疗",
                  "content": "这通常提供给被认为无法治愈的腹膜癌患者（当肿瘤无法完全切除时）。医生会使用腹腔手术的方式， 将化疗雾化并输送至腹部。这一种治疗通常需要几个周期，并通常与静脉化疗相结合。"
                }
              ]
            },
            {
              title: "general_surgery",
              content: "",
              contentCN: "",
              list:[
                {
                  "title": "Open inguinal hernia repair",
                  "content": "Hernia can be effectively repaired with traditional mesh technique. This is a simple surgery and is done as a daycare procedure. "
                },
                {
                  "title": "Laparoscopic surgery inguinal hernia repair",
                  "content": "Inguinal hernia can also be repaired laparoscopically. The incisions are smaller so the postoperative pain is less. They are especially useful in recurrent inguinal hernia that was repaired with open technique."
                },
                {
                  "title": "Open ventral hernia repair",
                  "content": "Ventral hernias are abdominal wall hernias other than inguinal hernia. They include umbilical hernia, paraumbilical hernia and incisional hernia. They can be repaired with open mesh technique."
                },
                {
                  "title": "Laparoscopic surgery ventral hernia repair",
                  "content": "Ventral hernias can be repaired laparoscopically as well."
                },
                {
                  "title": "Open and laparoscopic appendicectomy",
                  "content": "Appendicitis is a common condition and can affect any individual at all age groups. Appendicectomy is the treatment of choice and laparoscopic approach is the standard of care. Open surgery is only reserved for patients with more complex situations when surgery cannot be safely or optimally done laparoscopically."
                },
                {
                  "title": "Laparoscopic  cholecystectomy",
                  "content": "Gallstone is common and most people do not possess symptoms of gallstones, but nonetheless, some people do have significant symptoms and are at risk of developing complications from gallstones. Removal of gallbladder together with gallstones is done laparoscopically by default, and can be done as a daycare procedure. The recovery is fast and patients can return to their routine after a few days."
                },
                {
                  "title": "Single incision laparoscopic surgery",
                  "content": "Certain surgeries can be done through a single incision at the abdomen. This gives patients a better cosmetic outcome and less postoperative pain. It can be offered to suitable patients. One example of this surgery is single incision appendicectomy. "
                }
              ],
              listCN:[
                {
                  "title": "开放性腹股沟疝修补手术",
                  "content": "传统的补片技术可以有效修复疝气。 这是一个简单的手术，且可以作为日间护理程序进行。 "
                },
                {
                  "title": "微创腹股沟疝修补手术",
                  "content": "腹股沟疝也可以通过腹腔镜修复。 由于切口较小，因此术后疼痛也会较小。 它们对于通过开放技术修复的复发性腹股沟疝特别有用。"
                },
                {
                  "title": "开放性腹壁疝修补手术",
                  "content": "腹壁疝指的是腹股沟疝以外的腹壁疝气，其中包括脐疝、脐周疝和切口疝。这种手术可以使用开放性补片技术来修复。"
                },
                {
                  "title": "微创腹壁疝修补手术",
                  "content": "腹壁疝也可以通过腹腔手术来进行修补。"
                },
                {
                  "title": "开放性和微创阑尾切除手术",
                  "content": "阑尾炎是一种常见的疾病并可以影响所有年龄段的任何人。阑尾切除术是首选的治疗方法，而腹腔手术是作为这种疾病的标准治疗方法。开放手术仅适用于情况较复杂、无法通过腹腔镜安全进行手术的患者。"
                },
                {
                  "title": "微创胆囊切除手术",
                  "content": "胆结石是一种常见的疾病，大多数患者没有胆结石的症状。但尽管如此，有些人确实有明显的症状，并且有出现胆结石并发症的风险。。 绝大多数情况下，胆囊连同胆结石的切除是通过腹腔镜进行的，并且可以作为日间护理程序进行。通常病人会在短期内康复并恢复正常生活。"
                },
                {
                  "title": "单切口腹腔镜手术",
                  "content": "一些手术可以通过腹部中的一个小切口来进行。这种手术方案可以使病人获得更好的外观效果和更少的术后疼痛。这种手术的其中一个例子就是单切口腹腔阑尾切除手术。"
                }
              ]
            }
      ],
    };
  },
  created(){
    this.Services.forEach(item => {
      item.visible = false;
    });
    var id = this.$route.params.id || null
    if (id!==null) {
      console.log(parseInt(id))
      this.Services[parseInt(id)].visible = true
      document.title = `${this.capitalize(this.Services[parseInt(id)].title)} | Dr. Lim Surgery - Consultant General & Colorectal Surgeon`;
    }else{
      console.log("go")
      this.Services[0].visible = true
    }
    
  },
  mounted(){
    this.load()
    console.log("mounted")
    this.handleHashChange()
    this.setServiceList()
  },
  methods: {
    convertToSlug(str) {
      // Replace all spaces_with_underscores
      str = str.replace(/\s+/g, '-').replace(/_/g, '-').toLowerCase();
      return str;
    },
    setServiceList() {
      var data = this.Services.map((service, index) => ({
        id: index,
        name: this.convertToSlug(service.title),
      }));
      console.log(data)
      //this.$refs.commonFunc.setServiceData(data)
    },
    capitalize(string) {
      console.log(string)
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    },
    load(){
      var id = this.$route.params.id
      if (id!==null) {
        this.Services.forEach((service, index) => {
          if (index === parseInt(id)) {
            service.visible = true;
          } else {
            service.visible = false;
          }
        });
      }else{
        this.Services[0].visible = true
      } 
      
      // if (id!==null) {
      //   this.scrollToDiv("#service_"+parseInt(id))
      // }
    },
    scrollToDiv(id) {
      var element = document.querySelector(id);
      element.scrollIntoView({ behavior: "smooth" });
    },
    handleHashChange() {
      var id = this.$route.params.id || null;
     
       if (id!==null) {
        const servicesPage = document.getElementById("serviceBanner");
        const element = document.getElementById("service_"+id);
        //const element2 = document.getElementById("accordion-"+id);
        console.log("serviceBanner> " + servicesPage.scrollHeight )
        console.log("element> " + (element.offsetTop + element.scrollHeight ))
        if (element) {
          var heights = servicesPage.scrollHeight;
          console.log("height> " + heights)
          setTimeout(() => {
            window.scrollTo({
              top: heights,
              behavior: 'smooth'
            });
          }, 0); // Increase the delay time to give the browser a chance to reflow the page
        }
      }
     
    
    }
  },
}
</script>
<style scoped>

.servicesPage a.not-collapsed::after {
    content: "\f077";
    font-family: "Font Awesome 5 Free";
    position: absolute;
    font-size: 17px;
    right: 15px;
    top: 5px;
    -webkit-transform: translateY(50%);
    -moz-transform: translateY(50%);
    transform:translateY(50%);
    font-size: 17px;
}

.servicesPage a.collapsed::after {
    content: "\f078";
    font-family: "Font Awesome 5 Free";
    position: absolute;
    right: 15px;
    top: 5px;
    -webkit-transform: translateY(50%);
    -moz-transform: translateY(50%);
    transform:translateY(50%);
    font-size: 17px;
}
</style>